<template>
  <!-- v-loading="loading" -->
  <div class="bg-white box-shadow border-r px-20 pb-20" v-loading="loading">
    <div class="d-flex-center px-15 border-bottom">
      <h4 class="mr-auto color-333">{{ $t("account_fiat4") }}</h4>
      <el-button type="primary" size="mini" icon="el-icon-refresh" plain @click="fatchRecord()">{{ $t("base.refresh")
        }}</el-button>
    </div>

    <!-- <div class="select-zoom">
      <div class="select-item">
        <el-select
          v-model="select1"
          :placeholder='"請選擇新幣"'
          clearable
          filterable
          class="th-select"
          size="medium"
          style="width: 150px"
          @change="selectFn1"
        >
          <el-option :label='$t("account_recycle.select_val1")' :value="1"> </el-option>
          <el-option :label='$t("account_recycle.select_val2")' :value="2"> </el-option>
          <el-option :label='$t("account_recycle.select_val3")' :value="3"> </el-option>
        </el-select>
      </div>
    </div> -->

    <!-- 認購彈窗 -->
    <el-dialog :title="'認購'" width="500px" top="100px" :visible.sync="orderShow" :close-on-click-modal="false">
      <el-form ref="orderForm" v-loading="orderLoading" element-loading-background="#ffffff88" class="white-form"
        :model="orderFormData" label-position="top" :rules="rules">
        <div class="can_num">
          <div class="can_num_h">{{ $t("xinbiyure.can_num_h1") }}：</div>
          <div class="can_num_p">
            <span class="can_num_p_num">{{
    popupData.remaining_total ? popupData.remaining_total : 0
  }}</span>
            <span> USDT</span>
          </div>
        </div>
        <div class="can_num">
          <div class="can_num_h">{{ $t("xinbiyure.can_num_h_u") }}：</div>
          <div class="can_num_p">
            <span class="can_num_p_num">{{
    balance_coin ? balance_coin : 0
  }}</span>
            <span> USDT</span>
          </div>
        </div>
        <!-- :label="$t('issue.label')" -->
        <el-form-item prop="amount">
          <el-input v-model="orderFormData.amount" size="large" type="number" prefix-icon="el-icon-money"
            autocomplete="off" :placeholder='$t("xinbiyure.orderFormData_amount2")'>
          </el-input>
        </el-form-item>
        <div class="text-center">
          <el-button type="info" icon="el-icon-close" @click="onOrderHide()">
            {{
    $t("issue.Cancel")
  }}
          </el-button>
          <el-button type="primary" icon="el-icon-s-promotion" @click="submitOrderForm()">
            {{
    $t("issue.Submit")
  }}
          </el-button>
        </div>
      </el-form>
    </el-dialog>
    <!-- 認購彈窗 -end -->

    <!-- 新列表 -->
    <div class="list-outline">
      <!-- state：狀態0=申購中1=中簽2=完成3=完成(兩個都是完成) -->
      <el-row :gutter="20" type="flex" justify="center" align="middle" class="color-gray py-15 border-bottom font-14"
        style="padding-right: 20px; padding-left: 20px">
        <el-col :span="5">
          {{ $t('xinbiyure.span1') }}
        </el-col>
        <el-col :span="5">{{ $t('xinbiyure.span2') }}</el-col>
        <el-col :span="5">{{ $t('xinbiyure.span3') }}</el-col>
        <el-col :span="5">{{ $t('xinbiyure.span4') }}</el-col>
        <!-- <el-col :span="4">{{ $t('xinbiyure.span5') }}</el-col> -->
        <el-col :span="6">{{ $t('xinbiyure.span6') }}</el-col>
        <el-col :span="6" class="text-right">{{ $t('xinbiyure.span7') }}</el-col>
      </el-row>
      <div class="contactlist-item" v-for="(item, index) in list" :key="index">
        <div class="contactlist-item-into">
          <el-row :gutter="20" type="flex" justify="center" align="middle" class="py-15 border-bottom font-14"
            style="padding-right: 20px; padding-left: 20px">
            <el-col :span="5">
              {{ item.pair.toUpperCase() + " / " + item.coin.toUpperCase() }}
            </el-col>
            <el-col :span="5">{{ item.total }}</el-col>
            <el-col :span="5">{{ state_arr[Number(item.state)] }}</el-col>
            <el-col :span="5">{{
    item.success_total ? item.success_total : "---"
  }}</el-col>
            <!-- <el-col :span="4">{{
              item.remaining_total ? item.remaining_total : "---"
            }}</el-col> -->
            <el-col :span="6">{{
    item.create_time ? item.create_time : "---"
  }}</el-col>
            <el-col :span="6" class="text-right">
              <el-button type="success" size="small" v-if="item.state == 1&&item.remaining_total > 0"
                @click="buyFn(item)">{{ $t('xinbiyure.buyFn')}}</el-button>
              <span v-else>---</span>
            </el-col>
          </el-row>
        </div>
      </div>

      <!-- 舊列表，未加認購 -->
      <!-- <el-row
        :gutter="20"
        type="flex"
        justify="center"
        align="middle"
        class="color-gray py-15 border-bottom font-14"
        style="padding-right: 20px; padding-left: 20px"
      >
        <el-col :span="4">
            {{ $t('issue_order.col1') }}
        </el-col>
        <el-col :span="4">{{ $t('issue_order.col2') }}</el-col>
        <el-col :span="4">{{ $t('issue_order.col3') }}</el-col>
        <el-col :span="4">{{ $t('issue_order.col4') }}</el-col>
        <el-col :span="4">{{ $t('issue_order.col5') }}</el-col>
        <el-col :span="6" class="text-right">{{ $t('issue_order.col6') }}</el-col>
      </el-row>
      <div class="contactlist-item" v-for="(item, index) in list" :key="index">
        <div
          class="contactlist-item-into"
        >
          <el-row
            :gutter="20"
            type="flex"
            justify="center"
            align="middle"
            class="py-15 border-bottom font-14"
            style="padding-right: 20px; padding-left: 20px"
          >
            <el-col :span="4">
                {{item.pair.toUpperCase()+' / '+item.coin.toUpperCase()}}
            </el-col>
            <el-col :span="4">{{ item.issue_price }}</el-col>
            <el-col :span="4">{{ item.amount }}</el-col>
            <el-col :span="4">{{ item.total }}</el-col>
            <el-col :span="4">{{ item.close_day }}</el-col>
            <el-col :span="6" class="text-right">
              {{
                item.create_time
              }}
            </el-col>
          </el-row>
        </div>
      </div> -->
    </div>
    <!-- 新列表 -end -->

    <div class="pagination-outline">
      <div v-if="list.length < 1" class="py-50 font-14 color-i text-center">
        No More
      </div>
      <div class="pagination-box" v-else>
        <el-pagination layout="prev, pager, next" background :total="total1" :page-size="pageSize" :current-page="page"
          @current-change="pageFn1">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      record: {
        data: [],
      },
      state: [
        ["primary", "Trading"],
        ["info", "Cancel"],
        ["success", "Deal"],
      ],
      state_new: [
        this.$t("transactions.state_new1"),
        this.$t("transactions.state_new2"),
        this.$t("transactions.state_new3"),
        this.$t("transactions.state_new4"),
      ],
      list: [],
      page: 1,
      pageSize: 10,
      total1: 0,

      options1: [
        {
          value: 0,
          pair: this.$t("user_contract.rentext1"),
        },
        // {
        //   value: 1,
        //   pair: "BTC",
        // },
        // {
        //   value: 2,
        //   pair: "ETH",
        // },
        // {
        //   value: 3,
        //   pair: "DOGE",
        // },
      ],
      select1: "",

      options2: [
        {
          value: 0,
          label: this.$t("user_contract.rentext2"),
        },
        {
          value: 1,
          label: this.$t("user_contract.rentext3"),
        },
        {
          value: 2,
          label: this.$t("user_contract.rentext4"),
        },
      ],
      select2: 0,

      options3: [
        {
          value: 0,
          label: this.$t("user_contract.rentext5"),
        },
        {
          value: 1,
          label: this.$t("user_contract.rentext6"),
        },
        {
          value: 2,
          label: this.$t("user_contract.rentext7"),
        },
        {
          value: 3,
          label: this.$t("user_contract.rentext8"),
        },
        {
          value: 4,
          label: this.$t("user_contract.rentext9"),
        },
        {
          value: 5,
          label: this.$t("user_contract.rentext10"),
        },
      ],
      select3: 0,
      socket1: null,

      // state：狀態0=申購中1=中簽2=完成3=完成(兩個都是完成)
      // 加了認購後的狀態
      state_arr: [this.$t('xinbiyure.state_arr1'), this.$t('xinbiyure.state_arr2'), this.$t('xinbiyure.state_arr3'), this.$t('xinbiyure.state_arr4')],
      popupData: {},
      orderFormData: {
        amount: '',
      },
      orderLoading: false,
      orderShow: false,
      rules: {
        amount: [
          {
            required: true,
            message: this.$t('xinbiyure.amount_message'),
            trigger: "blur",
          },
        ],
      },

      // 可用余额
      balance_coin:null,
    };
  },
  created: function () {
    // this.fatchRecord()
  },
  mounted: function () {
    this.fatchRecord();
    this.getAccountBalance();
  },
  destroyed: function () { },
  methods: {

    // 新增獲取餘額usdt v1/symbol/getAccountBalance
    getAccountBalance: async function () {
      this.loading = true;
      try {
        const { data } = await this.$request.post("v1/symbol/getAccountBalance",{coin:'usdt',pair:'usdt'});
        this.balance_coin = data.balance.balance_coin
      } catch (e) {

      }
    },

    //認購操作
    buyFn(e) {
      // e整塊數據
      this.popupData = {}
      this.orderFormData = {
        amount: '',
      }

      // 先認證
      if (!this.$store.state.userToken) {
        return this.$confirm(
          this.$t("fiat_currency.message.p_userToken"),
          this.$t("fiat_currency.message.tips"),
          {
            distinguishCancelAndClose: true,
            confirmButtonText: this.$t("fiat_currency.message.confirm"),
            cancelButtonText: this.$t("fiat_currency.message.cancel"),
          }
        )
          .then(() => {
            // this.$router.push("../user/login");
            this.$router.push("/user/login");
          })
          .catch((action) => { });
      }
      if (this.$store.state.userVerified != 3) {
        return this.$confirm(
          this.$t("fiat_currency.message.p_userVerified"),
          this.$t("fiat_currency.message.tips"),
          {
            distinguishCancelAndClose: true,
            confirmButtonText: this.$t("fiat_currency.message.confirm"),
            cancelButtonText: this.$t("fiat_currency.message.cancel"),
          }
        )
          .then(() => {
            // this.$router.push("../user/settings/verify");
            this.$router.push("/user/settings/verify");
          })
          .catch((action) => { });
      }


      this.orderShow = true
      this.popupData = e
      console.log(e);
    },

    //關閉彈窗
    onOrderHide: function () {
      this.orderShow = false;
      this.orderFormData.amount = "";
    },

    //認購
    submitOrderForm: async function () {
      let that = this;

      try {
        await this.$refs.orderForm.validate();

        if (
          Number(this.orderFormData.amount) >
          Number(this.popupData.remaining_total)
        ) {
          return that.$message({
            showClose: true,
            type: "error",
            duration: 2000,
            message: that.$t("xinbiyure.showToast1_rengou"),
          });
        }

        // if (
        //   Number(this.orderFormData.amount) >
        //   Number(this.balance_coin)
        // ) {
        //   return that.$message({
        //     showClose: true,
        //     type: "error",
        //     duration: 2000,
        //     message: that.$t("xinbiyure.showToast_u"),
        //   });
        // }

        this.orderLoading = true;
        // this.orderFormData.amount = Number(this.orderFormData.amount)*100

        let $dataJson = {
          amount: this.orderFormData.amount,
          id: this.popupData.id,
        }

        let $data = await this.$request.post(
          "v1/issue_symbol/buy",
          $dataJson
        );

        that.$message({
          showClose: true,
          type: "success",
          duration: 2000,
          message: $data.message,
          onClose: function () {
            that.orderShow = false;
            that.orderLoading = false;
            that.page = 1;
            that.fatchRecord()
            that.getAccountBalance();
          },
        });
      } catch (error) {
        this.orderLoading = false;
      }
    },

    //订单
    fatchRecord: async function () {
      this.loading = true;
      const { data } = await this.$request.post(
        "v1/issue_symbol/getSubscriptionList",
        {
          page: this.page,
          pageSize: this.pageSize,
          issue_symbol_id: 0,
        }
      );
      this.list = data.list;
      this.total1 = data.total;
      this.loading = false;
    },

    pageFn1: function (e) {
      this.page = e;
      this.fatchRecord();
    },

    //
    selectFn1: function (e) {
      this.fatchRecord();
    },

    stopFn() { },
  },
};
</script>

<style lang="scss" scoped>
//
.pagination-outline {
  padding-top: 20px;
  box-sizing: border-box;
  text-align: center;
}

.newbtn {
  height: 32px;
  padding: 0 10px;
}

// .th-select input{
//     font-size: 14px;
//     font-weight: normal;
//     border: 0;
// }
.select-zoom {
  display: flex;
}

.select-item {
  margin-right: 20px;
  // width: 250px!important;
}

.select-zoom {
  padding: 20px 0;
  box-sizing: border-box;
  border-bottom: 1px solid #e3e6e9;
}

// 持仓列表
// <div class="coin-zoom">
//     <span class="coin-zoom-icon">
//     <i class="el-icon-caret-bottom"></i>
//     </span>
//     <div class="coin-zoom-into">
//     <strong>BTC / USDT</strong>
//     <span>Buy</span>
//     </div>
// </div>
.coin-zoom {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.coin-zoom-into {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.coin-zoom-into strong {
  font-size: 14px;
  line-height: 14px;
}

.coin-zoom-icon {
  width: 14px;
  height: 14px;
  transition: all ease 0.5s;
}

.detail-row {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 10px;
  box-sizing: border-box;
}

.detail-col {
  // display: flex;
  // flex-direction: column;
  // justify-content: flex-start;
  // align-items: flex-start;
  padding: 0 10px;
  box-sizing: border-box;
}

.span-h {
  font-size: 12px;
  color: #999;
  line-height: 20px;
  display: block;
  margin-bottom: 5px;
}

.span-p {
  display: block;
  font-size: 14px;
  color: #333;
  line-height: 20px;
  font-weight: bold;
}

.detail-row:nth-child(1) .detail-col {
  width: 25%;
}

.detail-row:nth-child(1) .detail-col:nth-child(4) {
  text-align: right;
}

.detail-row:nth-child(2) .detail-col {
  width: 25%;
}

.detail-row:nth-child(2) .detail-col:nth-child(4) {
  text-align: right;
}

.detail-row:nth-child(3) .detail-col {
  width: 50%;
}

.contactlist-item-detail {
  background: #f1f1f1;
  overflow: hidden;
  height: 0px;
  transition: all ease 0.5s;
}

.contactlist-item-detail-into {
  padding: 10px 0;
  box-sizing: border-box;
}

.contactlist-item-active .coin-zoom-icon {
  transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  /* IE 9 */
  -moz-transform: rotate(180deg);
  /* Firefox */
  -webkit-transform: rotate(180deg);
  /* Safari 和 Chrome */
  -o-transform: rotate(180deg);
  /* Opera */
}

.contactlist-item-into {
  cursor: pointer;
}

.contactlist-item-active .contactlist-item-detail {
  height: 300px;
}

.can_num {
  display: flex;
  align-items: center;
  line-height: 24px;
  margin-bottom: 5px;
}

.can_num_p_num {
  font-size: 18px;
  color: red;
  font-weight: bold;
}
</style>